<div aria-hidden="true" class="mt-3 center">
  <img src="../../../../assets/images/under-maintenance.svg" alt="Maintenance" width="90" />
</div>
<h3 mat-dialog-title class="text-secondary title-9 uppercase pl-0 mt-1 mb-1 text-w-700 justify-content-center">
  <ng-container *ngIf="data.title">
    {{data.title}}
  </ng-container>
  <ng-container *ngIf="!data.title">
    SCHEDULED MAINTENANCE
  </ng-container>
</h3>
<p role="alert" class="text-secondary center title-10 text-w-500 mt-1">
  <ng-container *ngIf="data.message">
    {{ data.message }}
  </ng-container>

  <ng-container *ngIf="!data.message">
    There is a planned maintenance. The app services will not be available
    during this time. Sorry for the inconvenience.
  </ng-container>
</p>

<mat-dialog-actions align="center">
  <button mat-raised-button attr.aria-label="Close the dialog" class="primary-btn uppercase" (click)="close()">
    OK
  </button>
</mat-dialog-actions>