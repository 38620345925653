import { error } from 'console';

export class ErrorMessagingConstants {
  public static FORMCONTROL_ERROR_MAP = {
    firstName: {
      required: (error) => 'This field cannot be empty',
      maxlength: (error) =>
        'First Name must have MAX_ALLOWED characters'.replace(
          /MAX_ALLOWED/,
          error?.requiredLength
        ),
      pattern: (error) => "Please enter valid first name that includes only letters and numbers and/or following symbols -.and/or'. ",
    },
    middleName: {
      required: (error) => 'This field cannot be empty',
      maxlength: (error) =>
        'Middle Name must have MAX_ALLOWED characters'.replace(
          /MAX_ALLOWED/,
          error?.requiredLength
        ),
      pattern: (error) => "Please enter valid middle name that includes only letters and numbers and/or following symbols -.and/or'. ",
    },
    lastName: {
      required: (error) => 'This field cannot be empty',
      maxlength: (error) =>
        'Last Name must have MAX_ALLOWED characters'.replace(
          /MAX_ALLOWED/,
          error?.requiredLength
        ),
      pattern: (error) => "Please enter valid last name that includes only letters and numbers and/or following symbols -.and/or'. ",
    },
    gender: {
      required: (error) => 'This field cannot be empty',
    },
    relationShip: {
      required: (error) => 'This field cannot be empty',
    },
    dob: {
      required: (error) => 'Please enter valid DOB',
      matDatepickerMax: (error) => 'Please enter valid DOB',
      matDatepickerParse: (error) => 'Please enter valid DOB',
    },
    ssn: {
      required: (error) => 'This field cannot be empty',
      minlength: (error) =>
        'SSN must have MIN_ALLOWED digits'.replace(/MIN_ALLOWED/, '9'),
      maxlength: (error) =>
        'SSN must have MAX_ALLOWED digits'.replace(/MAX_ALLOWED/, '9'),
      pattern: (error) => 'Invalid SSN please enter numbers only',
    },
    client: {
      required: (error) => 'This field cannot be empty',
    },
    group: {
      required: (error) => 'This field cannot be empty',
    },
    empiRxMemberId: {
      required: (error) => 'This field cannot be empty',
      maxlength: (error) =>
        'EmpiRx Member id must have MAX_ALLOWED characters'.replace(
          /MAX_ALLOWED/,
          error?.requiredLength
        ),
    },
    clientInternalMemberId: {
      required: (error) => 'This field cannot be empty',
      maxlength: (error) =>
        'CIM ID must have MAX_ALLOWED characters'.replace(
          /MAX_ALLOWED/,
          error?.requiredLength
        ),
    },
    cardId: {
      required: (error) => 'This field cannot be empty.',
      pattern: (error) => 'Invalid ID value.',
    },
    idType: {
      required: (error) => 'This field cannot be empty.',
    },
    idValue: {
      required: (error) => 'This field cannot be empty',
      pattern: (error) => 'Invalid ID value.',
    },
    coverageType: {
      required: (error) => 'This field cannot be empty',
    },
    coverageEffect: {
      required: (error) => 'Please enter valid effective date',
      matDatepickerMax: (error) => 'Please enter valid effective date',
      matDatepickerParse: (error) => 'Please enter valid effective date',
    },
    orderIdCard: {
      required: (error) => 'This field cannot be empty',
    },
    addressType: {
      required: (error) => 'This field cannot be empty',
    },
    addressLine1: {
      required: (error) => 'This field cannot be empty',
      maxlength: (error) =>
        'Address Line 1 must have MAX_ALLOWED characters'.replace(
          /MAX_ALLOWED/,
          error?.requiredLength
        ),
    },
    addressLine2: {
      required: (error) => 'This field cannot be empty',
      maxlength: (error) =>
        'Address Line 2 must have MAX_ALLOWED characters'.replace(
          /MAX_ALLOWED/,
          error?.requiredLength
        ),
    },
    zipCode: {
      invalidZipCode: (error) => 'The ZIP code must contain either 5 or 9 digits.',
      required: (error) => 'This field cannot be empty',
      minlength: (error) =>
        'Zip Code must have MIN_ALLOWED digits'.replace(
          /MIN_ALLOWED/,
          error?.requiredLength
        ),
      maxlength: (error) =>
        'Zip Code must have MAX_ALLOWED digits'.replace(
          /MAX_ALLOWED/,
          error?.requiredLength
        ),
    },
    city: {
      required: (error) => 'This field cannot be empty',
      pattern: (error) => 'Only characters are allowed',
    },
    state: {
      required: (error) => 'This field cannot be empty',
    },
    phoneType: {
      required: (error) => 'This field cannot be empty',
    },
    phoneNumber: {
      required: (error) => 'This field cannot be empty',
      minlength: (error) =>
        'Phone Number must have MIN_ALLOWED digits'.replace(
          /MIN_ALLOWED/,
          error?.requiredLength
        ),
      maxlength: (error) =>
        'Phone Number must have MAX_ALLOWED digits'.replace(
          /MAX_ALLOWED/,
          error?.requiredLength
        ),
    },
    email: {
      required: (error) => 'This field cannot be empty',
      pattern: (error) => 'Invalid Email',
    },
    startDate: {
      required: (error) => 'Please enter valid start date',
      matDatepickerMin: (error) => 'Please enter valid start date',
      matDatepickerMax: (error) => 'Please enter valid start date',
      matDatepickerParse: (error) => 'Please enter valid start date',
    },
    effectiveDate: {
      required: (error) => 'Please enter valid effective date',
      matDatepickerMax: (error) => 'Please enter valid effective date',
      matDatepickerParse: (error) => 'Please enter valid effective date',
    },
    endDate: {
      required: (error) => 'Please enter valid end date',
      matDatepickerMin: (error) => 'Please enter valid end date',
      matDatepickerMax: (error) => 'Please enter valid end date',
      matDatepickerParse: (error) => 'Please enter valid end date',
    },
    groupName: {
      required: (error) => 'This field cannot be empty',
    },
    from: {
      dateInvalid: (error) => '"From" cannot be greater than "To"',
      required: (error) => 'Please enter valid From date',
      matDatepickerMax: (error) => 'Please enter valid From date',
      matDatepickerParse: (error) => 'Please enter valid From date',
    },
    to: {
      dateInvalid: (error) => '"From" cannot be greater than "To"',
      required: (error) => 'Please enter valid To date',
      matDatepickerMax: (error) => 'Please enter valid To date',
      matDatepickerParse: (error) => 'Please enter valid To date',
    },
    formDateControl: {
      dateInvalid: (error) => '"From" cannot be greater than "To"',
      required: (error) => 'Please enter valid From date',
      matDatepickerMax: (error) => 'Please enter valid From date',
      matDatepickerParse: (error) => 'Please enter valid From date',
    },
    toDateControl: {
      dateInvalid: (error) => '"From" cannot be greater than "To"',
      required: (error) => 'Please enter valid To date',
      matDatepickerMax: (error) => 'Please enter valid To date',
      matDatepickerParse: (error) => 'Please enter valid To date',
    },
    quantity: {
      required: (error) => 'This field cannot be empty',
    },
    daysSupply: {
      required: (error) => 'This field cannot be empty',
    },
    radius: {
      required: (error) => 'This field cannot be empty',
    },
    mailTo: {
      required: (error) => 'This field cannot be empty',
    },
    address: {
      required: (error) => 'This field cannot be empty',
    },
    cardIssueDate: {
      required: (error) => 'Please enter valid Card Issue date',
      matDatepickerMax: (error) => 'Please enter valid Card Issue date',
      matDatepickerParse: (error) => 'Please enter valid Card Issue date',
    },
    reason: {
      required: (error) => 'This field cannot be empty',
    },
    year: {
      required: (error) => 'This field cannot be empty',
    },
  };
}
