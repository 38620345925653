import {
  Component,
  ElementRef,
  OnDestroy,
  OnInit,
  Renderer2,
  SecurityContext,
} from '@angular/core';
import { MatTableDataSource } from '@angular/material/table';
import { DomSanitizer, SafeUrl } from '@angular/platform-browser';
import { ActivatedRoute, Router } from '@angular/router';
import { Observable, Subscription, defer, of } from 'rxjs';
import { switchMap, map, tap, catchError } from 'rxjs/operators';
import { AppConstants } from 'src/app/app.constants';
import { EligibilityService } from 'src/app/core/services/eligibility/eligibility.service';
import { MemberDetails } from 'src/app/interfaces/member.interface';
import { PrintDigitalIDCardRequestInterface } from 'src/app/interfaces/print-digital-id-card.interface';
import { LoaderService } from '../../services/loader/loader.service';
import { AppCenterAnalytics } from 'src/app/services/app-insights/app-insights';
import { AppInsightsService } from 'src/app/services/app-insights/app-insights.service';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { ErrorComponent } from 'src/app/core/components/error/error.component';
import { BenefitManagementService } from 'src/app/core/services/benefit-management/benefit-management.service';
import { SharedService } from '../../../services/shared/shared.service';

@Component({
  selector: 'app-print-id-card',
  templateUrl: './print-id-card.component.html',
  styleUrls: ['./print-id-card.component.scss'],
})
export class PrintIdCardComponent implements OnInit, OnDestroy {
  public selectedMemberDetails$: Observable<Partial<MemberDetails>>;
  public memberDetails: MemberDetails;
  public displayCoverageType = AppConstants.DISPLAY_COVERAGE_TYPE;
  public externalClientIdsMapping = AppConstants.EXTERNAL_CLIENT_IDS_MAPPING;
  imageUrl: SafeUrl;
  private subscription: Subscription;
  public relationShip = AppConstants.RELATIONSHIP_VALUES;
  public isIdCardAvailable: boolean = true;
  constructor(
    private readonly router: Router,
    private eligibilityService: EligibilityService,
    private activatedRoute: ActivatedRoute,
    private sanitizer: DomSanitizer,
    private renderer: Renderer2,
    private loader: LoaderService,
    private appInsightsService: AppInsightsService,
    private matDialog: MatDialog,
    private sharedService: SharedService
  ) { }

  ngOnInit(): void {
    this.appInsightsService.trackPageView(AppCenterAnalytics.PRINT_ID_CARD);
    this.selectedMemberDetails$ = this.activatedRoute.queryParams.pipe(
      switchMap((res) => {
        return this.eligibilityService.getMemberDetailsById(res).pipe(
          map((res) => {
            this.memberDetails = res[0];
            return res[0];
          }),
          tap((res) => {
            // console.log('Response on member details:', res);
            this.getCardDetails();
          }),
          catchError((err) => {
            return of([]);
          })
        );
      })
    );
  }

  public navigateBackToMemberSearch(): void {
    this.router.navigateByUrl('/management/online-eligibility');
  }

  getCardDetails() {
    const reqBody: PrintDigitalIDCardRequestInterface = {
      clientId: this.memberDetails.clientId,
      cardId: this.memberDetails.cardId,
      personCode: this.memberDetails.personCode,
    };

    this.subscription = defer(() => {
      this.loader.showLoader();
      return this.eligibilityService.printDigitalIDCard(reqBody);
    }).subscribe({
      next: (imageData: ArrayBuffer) => {
        this.isIdCardAvailable = true;
        const blob = new Blob([imageData], { type: 'image/png' });
        const reader = new FileReader();
        this.appInsightsService.trackPageView(AppCenterAnalytics.PRINT_ID_CARD_API_SUCCESS, { Response: imageData });
        reader.onloadend = () => {
          const sanitizedUrl = this.sanitizer.bypassSecurityTrustUrl(
            reader.result as string
          );
          this.imageUrl = sanitizedUrl;
        };

        reader.onerror = (error) => {
          console.error('Error reading image data:', error);
        };

        reader.readAsDataURL(blob);
      },
      error: (err) => {
        this.appInsightsService.trackPageView(AppCenterAnalytics.PRINT_ID_CARD_API_ERROR, { Response: err });
        this.isIdCardAvailable = false;
        this.loader.hideLoader();
      },
      complete: () => {
        // this.loader.hideLoader();
        // console.log('Print ID card request complete.');
      },
    });
  }

  get isLevelD(): boolean {
    return this.sharedService.isLevelD();
  }

  openPrintWindow() {
    if (this.imageUrl) {
      const printWindow = window.open('', '_blank', 'width=600,height=600');
      if (printWindow) {
        const url: string =
          this.sanitizer.sanitize(SecurityContext.URL, this.imageUrl) || '';
        printWindow.document.open();
        printWindow.document.write(`
        <html>
          <head>
            <style>
              @media print {
                body {
                  margin: 0;
                }
                img {
                  width: 100%;
                  height: auto;
                }
              }
            </style>
          </head>
          <body>
            <img src="${url}" alt="ID Card">
            <script>
              window.addEventListener('afterprint', function() {
                window.close();
              });
            </script>
          </body>
        </html>
      `);
        printWindow.document.close();
        printWindow.print();
      }
    }
  }

  downloadImage() {
    if (this.imageUrl) {
      const link = this.renderer.createElement('a');
      const url: string =
        this.sanitizer.sanitize(SecurityContext.URL, this.imageUrl) || '';

      this.renderer.setAttribute(link, 'href', url);
      this.renderer.setAttribute(link, 'download', 'id_card.png');
      this.renderer.setStyle(link, 'display', 'none');
      this.renderer.appendChild(document.body, link);
      link.click();

      this.renderer.removeChild(document.body, link);
    }
  }

  ngOnDestroy(): void {
    if (this.subscription) this.subscription.unsubscribe();
  }
}
