import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { AppConstants } from '../../../app.constants';
import { MatTableDataSource } from '@angular/material/table';
import { defer, Observable, of } from 'rxjs';
import { map, switchMap, tap } from 'rxjs/operators';
import {
  IFamilyCoverageResponse,
  MemberCoverage,
  MemberDetails,
} from 'src/app/interfaces/member.interface';
import { ActivatedRoute, Router } from '@angular/router';
import {
  clearSsnFormat,
  numericOnly as externalNumericOnly,
  formatDate,
  getErrorMessage,
} from 'src/app/util';
import { AppInsightsService } from 'src/app/services/app-insights/app-insights.service';
import { AppCenterAnalytics } from 'src/app/services/app-insights/app-insights';
import { EligibilityService } from '../../../core/services/eligibility/eligibility.service';
import { LoaderService } from '../../services/loader/loader.service';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { AddMemberResponseDialogComponent } from '../add-member/add-member-response-dialog/add-member-response-dialog.component';
import { SaveAuditLogsRequestInterface } from 'src/app/interfaces/audit-logs.interface';
import { AuditLogsService } from 'src/app/core/services/audit-logs/audit-logs.service';
import { AuthService } from 'src/app/core/services/auth/auth.service';
import { ClientInfoResponse, ILocationCodes } from 'src/app/interfaces/UserGroup.interface';
import { ErrorManagerService, ErrorType } from '../../../core/services/error';
import { HttpErrorResponse } from '@angular/common/http';

@Component({
  selector: 'app-add-dependent',
  templateUrl: './add-dependent.component.html',
  styleUrls: ['./add-dependent.component.scss'],
})
export class AddDependentComponent implements OnInit {
  public isLinear = true;
  public personalIfoFormGroup: FormGroup;
  public checkTouchForPersonalInfo = false;
  public contactInfoFormGroup: FormGroup;
  public phoneTypes: { value: string; name: string }[] = [
    { value: '', name: 'Select Phone Type' },
    { value: 'homePhone', name: 'Home Phone' },
    { value: 'cellPhone', name: 'Cell Phone' },
    { value: 'workPhone', name: 'Work Phone' },
  ];
  public checkTouchForContactInfo = false;
  public maxDate = new Date();
  public genderList: { key: string; name: string }[] = AppConstants.GENDER_LIST;
  public personCodes: string[] = ['03', '04', '05'];
  public relationshipData: { name: string; value: string }[] =
    AppConstants.RELATIONSHIP_CODE;
  public selectedMemberDetails$: Observable<Partial<MemberDetails>>;
  public memberDetails: MemberDetails;
  public displayGender = AppConstants.DISPLAY_GENDER;
  public displayCoverageType = AppConstants.DISPLAY_COVERAGE_TYPE;
  public relationShip = AppConstants.RELATIONSHIP_VALUES;
  public effectiveDateMaxDate = new Date('12/31/9999');

  public displayedColumns: string[] = [
    'name',
    'cardId',
    'personCode',
    'relationshipCode',
    'gender',
    'DOB',
    'startDate',
    'endDate',
  ];
  dataSource!: MatTableDataSource<MemberDetails>;
  dependents$!: Observable<MemberDetails[]>;
  public totalDependents: number;
  numericOnly = externalNumericOnly;
  newDependent: Partial<MemberDetails> = {};

  groupInfo: { groupId: string; groupName: string } = {
    groupId: '',
    groupName: '',
  };
  clientInfo$: Observable<ClientInfoResponse[]>;
  groupInfo$: Observable<{ groupId: string; groupName: string }> =
    new Observable();
  locationCode: ILocationCodes[];
  constructor(
    private formBuilder: FormBuilder,
    private router: Router,
    private readonly appInsightService: AppInsightsService,
    private readonly eligibilityService: EligibilityService,
    private activatedRoute: ActivatedRoute,
    private loader: LoaderService,
    private matDialog: MatDialog,
    private auditLogsService: AuditLogsService,
    private authService: AuthService,
    private errorManager: ErrorManagerService
  ) { }
  a =
    'eyJhbGciOiJSUzI1NiIsImtpZCI6Ilg1ZVhrNHh5b2pORnVtMWtsMll0djhkbE5QNC1jNTdkTzZRR1RWQndhTmsiLCJ0eXAiOiJKV1QifQ.eyJ2ZXIiOiIxLjAiLCJpc3MiOiJodHRwczovL215cG9ydGFsZGV2LmIyY2xvZ2luLmNvbS8zZTIyNGQ4OC0wN2UwLTRhYTMtODZlZC1iYzg2M2NkZDQyNWIvdjIuMC8iLCJzdWIiOiJlZWY5MjI5NC01Nzc1LTQzOTEtYjBiZS1kYWIwZjkzMjMxN2QiLCJhdWQiOiIzN2UwNmQ3MS00OWRkLTRiMWYtOTEyZi0wYzY1OTE2NzA2NzQiLCJleHAiOjE3MDAwMzk0MDMsIm5vbmNlIjoiZGVmYXVsdE5vbmNlIiwiaWF0IjoxNzAwMDI4NjAzLCJhdXRoX3RpbWUiOjE3MDAwMjg2MDMsImVtYWlscyI6WyJtdmlkZWthckBlbXBpcnhoZWFsdGguY29tIl0sInRmcCI6IkIyQ18xX1NpZ25VcFNpZ25JbiIsIm5iZiI6MTcwMDAyODYwM30.C-qFK2b3wRdb3zGgCw7_64vLlA6WH6CDgXCzY_2DHbYNBresWU3C9lLL9pm9XqX8BIfbHZEzSbWvVzQq8oNnuybyMH4KYsbiIJTVtodpRtCERCqE5W_GWwBAhPzrxj-4SjIM2leYOHpfK5qvkfZTT6f1nn7Bm1dsi_p5xUbULeRDAel90BXZBoKim2TJ8Tvl3N9Pnu5hWFOatYH9WVU-q0nzp9EJRRANvTN6aLYzzPyP4MsFh6gJfx8UoDOEx4Ftnkz7sdrReTPn7ItN_0PwNiwiI-5CbVEAJhgn_yTVtnwVBfX4Lg7TFShLvvk9Xr-tmky7aFmykrZ1ZS23XkxpSw';
  ngOnInit(): void {
    const requiredValidator = Validators.required;
    const emailValidator = Validators.pattern(
      AppConstants.EMAIL_VALIDATION_PATTERN
    );
    this.personalIfoFormGroup = this.formBuilder.group({
      firstName: ['', [requiredValidator, Validators.pattern(AppConstants.NAME_VALIDATION_PATTERN), Validators.maxLength(35)]],
      middleName: ['', [Validators.maxLength(35), Validators.pattern(AppConstants.NAME_VALIDATION_PATTERN)]],
      lastName: ['', [requiredValidator, Validators.pattern(AppConstants.NAME_VALIDATION_PATTERN), Validators.maxLength(35)]],
      gender: ['', requiredValidator],
      dob: ['', requiredValidator],
      ssn: [
        '',
        [
          Validators.maxLength(9),
          Validators.minLength(9),
          Validators.pattern(/^\d{1,9}$/),
        ],
      ],
      clientInternalMemberId: ['', [Validators.maxLength(9)]],
      effectiveDate: ['', requiredValidator],
      relationShip: ['', requiredValidator],
      coverageType: ['FAM', requiredValidator],
    });
    this.selectedMemberDetails$ = this.activatedRoute.queryParams.pipe(
      switchMap((res) => {
        const reqObj = { loading: true, ...res }
        return this.eligibilityService.getMemberDetailsById(reqObj).pipe(
          map((res) => {
            this.memberDetails = res[0];
            if (this.memberDetails.clientInternalMemberId) {
              this.personalIfoFormGroup.get('clientInternalMemberId').setValidators(requiredValidator);
            }
            this.groupInfo$ = this.eligibilityService
              .getGroupInfo(
                this.memberDetails.clientId,
                this.memberDetails.coverage.groupNumber
              )
              .pipe(
                tap((res) => {
                  this.groupInfo = res;
                  this.clientInfo$ = this.eligibilityService
                    .getClientDetailsForBenefit(this.memberDetails.clientId)
                    .pipe(
                      tap((res) => {
                        for (let detail of res) {
                          for (let plan of detail.plans) {
                            for (let group of plan.groups) {
                              if (
                                group.groupId.toLowerCase() ===
                                this.memberDetails.coverage.groupNumber.toLowerCase()
                              ) {
                                this.locationCode = group.locationCodes;
                                break;
                              }
                            }
                          }
                        }
                      })
                    );
                })
              );
            this.dependents$ = this.getDependents().pipe(
              tap((res) => {
                res.forEach(
                  (res) =>
                  (res.relationshipValue =
                    AppConstants.RELATIONSHIP_VALUES[res.relationshipCode])
                );
                // console.log(res);
                this.dataSource = new MatTableDataSource(res);
                this.totalDependents = res.length;
              })
            );
            return res[0];
          })
        );
      })
    );
    this.appInsightService.trackPageView(AppCenterAnalytics.ADD_DEPENDENT);

    this.contactInfoFormGroup = this.formBuilder.group({
      phoneType: [''],
      phoneNumber: [
        { value: '', disabled: true },
        [Validators.minLength(10), Validators.maxLength(10)],
      ],
      email: ['', [emailValidator]],
    });
    this.contactInfoFormGroup.controls.phoneType.valueChanges.subscribe(
      (value) => {
        if (value == null || value == '') {
          this.contactInfoFormGroup.get('phoneNumber')?.disable();
          this.contactInfoFormGroup.get('phoneNumber').setValidators(null);
          this.contactInfoFormGroup.controls.phoneNumber.setValue('');
          this.contactInfoFormGroup.get('phoneNumber').updateValueAndValidity();
        } else {
          this.contactInfoFormGroup.get('phoneNumber')?.enable();
          this.contactInfoFormGroup
            .get('phoneNumber')
            .setValidators([Validators.required, Validators.maxLength(10), Validators.minLength(10)]);
          this.contactInfoFormGroup.get('phoneNumber').updateValueAndValidity();
        }
      }
    );
  }

  public addIndependent(): void {
    const reqObj: MemberDetails = {
      clientId: this.memberDetails.clientId,
      cardId: this.memberDetails.cardId,
      firstName: this.personalIfoFormGroup.value.firstName.trim(),
      lastName: this.personalIfoFormGroup.value.lastName.trim(),
      middleName: this.personalIfoFormGroup.value.middleName.trim(),
      DOB: formatDate(new Date(this.personalIfoFormGroup.value.dob)),
      gender: this.personalIfoFormGroup.value.gender,
      clientInternalMemberId: this.personalIfoFormGroup.value.clientInternalMemberId,
      SSN: clearSsnFormat(this.personalIfoFormGroup.value.ssn),
      email: this.contactInfoFormGroup.value.email,
      addressLine1: this.memberDetails.addressLine1,
      addressLine2: this.memberDetails.addressLine2,
      zipCode: this.memberDetails.zipCode,
      city: this.memberDetails.city,
      state: this.memberDetails.state,
      effectiveDate: formatDate(
        new Date(this.personalIfoFormGroup.value.effectiveDate)
      ),
      isUpdated: false,
      groupNumber: this.memberDetails.coverage?.groupNumber,
      coverageType: this.personalIfoFormGroup.value.coverageType,
      relationshipCode: this.personalIfoFormGroup.value.relationShip,
    };
    if (this.contactInfoFormGroup.value.phoneType !== '') {
      reqObj[this.contactInfoFormGroup.value.phoneType] =
        this.contactInfoFormGroup.value.phoneNumber;
    }
    this.loader.showLoader();
    this.loader.showWaitingMessage$.next(AppConstants.ERROR.WAIT_MESSAGE_FOR_UPDATE_MEMBER);
    this.eligibilityService.addMember(reqObj).subscribe({
      next: (res) => {
        if (res) {
          // console.log('Inside response');
          res.PhoneType = this.contactInfoFormGroup.value.phoneType;
          res.firstName = this.personalIfoFormGroup.value.firstName;
          res.dateOfBirth = formatDate(
            new Date(this.personalIfoFormGroup.value.dob)
          );
          res.lastName = this.personalIfoFormGroup.value.lastName;
          res.coverageType = this.personalIfoFormGroup.value.coverageType;
          res[res.PhoneType] = this.contactInfoFormGroup.value.phoneNumber;
          res.gender = this.personalIfoFormGroup.value.gender;
          res.startDate = formatDate(
            new Date(this.personalIfoFormGroup.value.effectiveDate)
          );
          res.patientNum = this.memberDetails.cardId;
          res.email = this.contactInfoFormGroup.value.email;
          (res.addressLine1 = this.memberDetails.addressLine1),
            (res.addressLine2 = this.memberDetails.addressLine2),
            (res.zipCode = this.memberDetails.zipCode);
          res.city = this.memberDetails.city;
          res.state = this.memberDetails.state;
          this.newDependent = res;
          this.loader.showWaitingMessage$.next(null);
          this.loader.hideLoader();
          const dialogRef: MatDialogRef<AddMemberResponseDialogComponent> =
            this.matDialog.open(AddMemberResponseDialogComponent, {
              autoFocus: false,
              maxWidth: window.innerWidth < 766 ? 350 : 800,
              minWidth: window.innerWidth < 766 ? 350 : 700,
              data: res,
            });

          dialogRef.afterClosed().subscribe(() => {
            this.navigateBackToMemberSearch();
            // console.log('closed');
          });
        }
      },
      error: (err) => {
        this.createAuditLog(false, err, reqObj);
        const message = getErrorMessage(err);
        this.errorManager.throwError({
          type: ErrorType.ERROR_GENERIC,
          message: message,
          component: 'Add Dependent',
          title: '',
        });
        this.loader.showWaitingMessage$.next(null);
        this.loader.hideLoader();
      },
      complete: () => {
        this.createAuditLog(true);
      },
    });
  }

  createAuditLog(isSuccess: boolean, err?: HttpErrorResponse, memberDetails?: MemberDetails) {
    if (memberDetails) {
      this.newDependent = memberDetails;
      this.newDependent.dateOfBirth = memberDetails.DOB.toString();
    }
    const reqBody: SaveAuditLogsRequestInterface = {
      clientId: this.memberDetails.clientId,
      cardId: this.memberDetails.cardId,
      personCd: this.newDependent.personCode,
      firstName: this.newDependent.firstName,
      lastName: this.newDependent.lastName,
      emailId:
        this.newDependent.email &&
          this.newDependent.email.toLowerCase() !== 'null'
          ? this.newDependent.email
          : this.authService.userEmail,
      birthDt: this.newDependent.dateOfBirth,
      groupNo: this.newDependent.groupNum,
      groupName: this.groupInfo.groupName,
      changes:
        AppConstants.AUDIT_LOGS.addDependent.action.addNewDependent.change,
      comments:
        AppConstants.AUDIT_LOGS.addDependent.action.addNewDependent
          .comments,
      addedBy: this.authService.userEmail,
      isSuccess: isSuccess
    };
    if (!isSuccess) {
      const message = err?.error?.Message ? err.error.Message : JSON.stringify(err);
      reqBody.httpStatusCode = err.status,
        reqBody.errorMsg = message
    }
    // console.log('Request Body create audit log:', reqBody);
    this.auditLogsService.saveAuditLog(reqBody).subscribe({
      next: (res) => {
        this.appInsightService.trackPageView(AppCenterAnalytics.SAVE_AUDIT_LOG_API_SUCCESS, { Response: res });
      },
      error: (err) => {
        this.appInsightService.trackPageView(AppCenterAnalytics.SAVE_AUDIT_LOG_API_ERROR, { Response: err });
        this.loader.hideLoader();
      },
      complete: () => {
        this.loader.hideLoader();
      },
    });
  }

  public cancel(): void {
    this.router.navigateByUrl('/management/online-eligibility');
  }

  public navigateBackToMemberSearch(): void {
    this.router.navigateByUrl('/management/online-eligibility');
  }

  public getDependents(): Observable<MemberDetails[]> {
    const details = {
      clientId: this.memberDetails.clientId,
      cardId: this.memberDetails.cardId,
    };
    const query = this.eligibilityService.getSearchQuery(details);
    return defer(() => {
      this.loader.showLoader();
      return this.eligibilityService
      .getMemberDetails(query)
      .pipe(
        map((res) => {
          this.loader.hideLoader();
          this.checkIfSpouseExists(res);
          return res.filter(
            (i) =>
              i.relationshipCode !==
              AppConstants.RELATIONSHIP_CODES_ALL.CARDHOLDER
          )
        }
        )
      )});
  }

  private checkIfSpouseExists(res: MemberDetails[]) {
    if (res.some((m) => m.relationshipCode === AppConstants.RELATIONSHIP_CODES_ALL.SPOUSE)) {
      const index = this.relationshipData.findIndex((d) => d.value == AppConstants.RELATIONSHIP_CODES_ALL.SPOUSE);
      if (index !== -1) {
        this.relationshipData.splice(index, 1);
      }
    }
  }
  
}
