import { Component, OnInit, OnDestroy } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Route, Router } from '@angular/router';
import { AppConstants } from '../../../app.constants';
import {
  clearSsnFormat,
  numericOnly as externalNumericOnly,
  formatDate,
  formatSSN,
  getErrorMessage,
} from 'src/app/util';
import { AppInsightsService } from 'src/app/services/app-insights/app-insights.service';
import { AppCenterAnalytics } from 'src/app/services/app-insights/app-insights';
import { EligibilityService } from 'src/app/core/services/eligibility/eligibility.service';
import {
  ICreateMemberReq,
  MemberDetails,
} from 'src/app/interfaces/member.interface';
import { defer, Observable } from 'rxjs';
import { tap, shareReplay, switchMap, map } from 'rxjs/operators';
import { LoaderService } from '../../services/loader/loader.service';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { ErrorComponent } from '../../../core/components/error/error.component';
import { SaveAuditLogsRequestInterface } from 'src/app/interfaces/audit-logs.interface';
import { AuditLogsService } from 'src/app/core/services/audit-logs/audit-logs.service';
import { AuthService } from 'src/app/core/services/auth/auth.service';
import { ClientInfoResponse, ILocationCodes } from 'src/app/interfaces/UserGroup.interface';
import { ErrorManagerService, ErrorType } from '../../../core/services/error';
import { HttpErrorResponse } from '@angular/common/http';

@Component({
  selector: 'app-update-member',
  templateUrl: './update-member.component.html',
  styleUrls: ['./update-member.component.scss'],
})
export class UpdateMemberComponent implements OnInit {
  public updateMemberFormGroup: FormGroup;
  public genderList: { key: string; name: string }[] = AppConstants.GENDER_LIST;
  public maxDate = new Date();
  public displayCoverageType = AppConstants.DISPLAY_COVERAGE_TYPE;
  public relationShip = AppConstants.RELATIONSHIP_VALUES;
  private initialUpdateMemberFormValues: any;
  public relationshipData: { name: string; value: string }[] =
    AppConstants.RELATIONSHIP_CODE;
  public memberDetailsForm: {
    firstName: string;
    middleName: string;
    lastName: string;
    dob: Date;
    gender: string;
    ssn: string;
    clientInternalMemberId: string;
    relationshipCode: string;
  } = {
      firstName: '',
      middleName: '',
      lastName: '',
      dob: new Date(),
      gender: '',
      ssn: '',
      clientInternalMemberId: '',
      relationshipCode: '',
    };

  public selectedMemberDetails$: Observable<MemberDetails[]>;
  public memberDetails: MemberDetails;
  public locationCode: ILocationCodes[];
  public cardHolderRC: string = AppConstants.RELATIONSHIP_CODES_ALL.CARDHOLDER;
  numericOnly = externalNumericOnly;

  updatedMember: Partial<MemberDetails> = {};

  groupInfo: { groupId: string; groupName: string } = {
    groupId: '',
    groupName: '',
  };
  clientInfo$: Observable<ClientInfoResponse[]>;

  constructor(
    private readonly router: Router,
    private readonly formBuilder: FormBuilder,
    private readonly appInsightsService: AppInsightsService,
    private eligibilityService: EligibilityService,
    private activatedRoute: ActivatedRoute,
    private loader: LoaderService,
    private matDialog: MatDialog,
    private auditLogsService: AuditLogsService,
    private authService: AuthService,
    private errorManager: ErrorManagerService
  ) { }

  ngOnInit(): void {
    this.appInsightsService.trackPageView(AppCenterAnalytics.UPDATE_MEMBER);

    const requiredValidator = Validators.required;
    this.updateMemberFormGroup = this.formBuilder.group({
      firstName: ['', [requiredValidator, Validators.pattern(AppConstants.NAME_VALIDATION_PATTERN), Validators.maxLength(35)]],
      middleName: ['', [Validators.maxLength(35), Validators.pattern(AppConstants.NAME_VALIDATION_PATTERN)]],
      lastName: ['', [requiredValidator, Validators.pattern(AppConstants.NAME_VALIDATION_PATTERN), Validators.maxLength(35)]],
      gender: ['', requiredValidator],
      dob: ['', requiredValidator],
      ssn: ['', [Validators.maxLength(11), Validators.minLength(9)]],
      clientInternalMemberId: [
        '',
        [Validators.maxLength(9), Validators.pattern(/^\d{1,9}$/)],
      ],
      relationshipCode: ['']
    });

    this.selectedMemberDetails$ = defer(
      () => {
        this.loader.showLoader();
       return this.activatedRoute.queryParams.pipe(
          switchMap((params) => {
            const details = {
              clientId: params.clientId,
              cardId: params.cardId,
            };
            const query = this.eligibilityService.getSearchQuery(details);
            return this.eligibilityService.getMemberDetails(query).pipe(
              tap((res) => this.memberDetails = res.filter((m) => m.patientId == params.patientId)[0]),
              tap((res) => {
                this.loader.hideLoader();
                if (this.memberDetails.clientInternalMemberId) {
                  this.updateMemberFormGroup.get('clientInternalMemberId').setValidators([Validators.required])
                }
                if (this.memberDetails.relationshipCode !== this.cardHolderRC) {
                  this.updateMemberFormGroup.get('relationshipCode').setValidators([Validators.required])
                }
                this.relationshipData = AppConstants.RELATIONSHIP_CODE_TO_BE_UPDATED[this.memberDetails.relationshipCode];
                if ((this.memberDetails.relationshipCode !== AppConstants.RELATIONSHIP_CODES_ALL.SPOUSE && this.memberDetails.relationshipCode !== this.cardHolderRC) && this.checkIfSpouseExists(res)) {
                  const i = this.relationshipData.findIndex((c) => c.value === AppConstants.RELATIONSHIP_CODES_ALL.SPOUSE);
                  if (i !== -1) {
                    this.relationshipData.splice(i, 1);
                  }
                }
                this.memberDetailsForm.firstName = this.memberDetails.firstName;
                this.memberDetailsForm.lastName = this.memberDetails.lastName;
                this.memberDetailsForm.middleName = this.memberDetails.middleName;
                this.memberDetailsForm.dob = new Date(`${this.memberDetails.DOB}T00:00:00`);
                this.memberDetailsForm.gender = this.memberDetails.gender;
                this.memberDetailsForm.ssn =
                  this.memberDetails.SSN
                    ? formatSSN(this.memberDetails.SSN)
                    : '';
                this.memberDetailsForm.clientInternalMemberId =
                  this.memberDetails.clientInternalMemberId;
                this.memberDetailsForm.relationshipCode = this.memberDetails.relationshipCode;
                this.populateMemberDetails(this.memberDetailsForm);
                this.clientInfo$ = this.eligibilityService
                  .getClientDetailsForBenefit(this.memberDetails.clientId)
                  .pipe(
                    tap((res) => {
                      // console.log('Res get client details:', res);
                      let result = {};

                      for (let detail of res) {
                        for (let plan of detail.plans) {
                          for (let group of plan.groups) {
                            if (
                              group.groupId?.toUpperCase() ===
                              this.memberDetails.coverage.groupNumber?.toUpperCase()
                            ) {
                              this.locationCode = group.locationCodes;
                              result = {
                                groupName: group.groupName,
                                groupId: group.groupId,
                              };
                              break;
                            }
                          }
                        }
                      }

                      this.groupInfo.groupId = result['groupId']
                        ? result['groupId']
                        : '';
                      this.groupInfo.groupName = result['groupName']
                        ? result['groupName']
                        : '';
                      // console.log('Group Info:', this.groupInfo);
                    })
                  );
              })
            );
          })
        )
      });
  }

  private checkIfSpouseExists(res: MemberDetails[]) {
    return res.filter((m) => m.relationshipCode === AppConstants.RELATIONSHIP_CODES_ALL.SPOUSE).length;
  }

  private populateMemberDetails(memberDetails) {
    this.updateMemberFormGroup.patchValue(memberDetails);
    this.initialUpdateMemberFormValues = {
      ...this.updateMemberFormGroup.value,
    };
  }

  ssnChange(event: any) {
    // console.log(this.updateMemberFormGroup.controls['ssn']);
  }

  public navigateBackToMemberSearch(): void {
    this.router.navigateByUrl('/management/online-eligibility');
  }

  public checkIfAnythingUpdated(): boolean {
    return (
      JSON.stringify(this.updateMemberFormGroup.value) !==
      JSON.stringify(this.initialUpdateMemberFormValues)
    );
  }

  limitInputLength(event: KeyboardEvent): void {
    const input = event.target as HTMLInputElement;
    const maxLength = 11;

    if (input.value.length >= maxLength && event.key.length === 1) {
      event.preventDefault();
    }
  }

  updatedFieldsArray(): string[] {
    const fieldsToCheck = [
      'firstName',
      'middleName',
      'lastName',
      'gender',
      'dob',
      'ssn',
      'clientInternalMemberId',
      'relationshipCode'
    ] as const;

    // Mapping of form field names to display names
    const fieldDisplayNameMap: Record<string, string> = {
      firstName: 'First Name',
      middleName: 'Middle Name',
      lastName: 'Last Name',
      gender: 'Gender',
      dob: 'DOB',
      ssn: 'SSN',
      clientInternalMemberId: 'Client Internal Member ID',
      relationshipCode: 'Relationship'
    };

    return fieldsToCheck
      .filter(
        (field) =>
          this.updateMemberFormGroup.value[field] !==
          this.initialUpdateMemberFormValues[field]
      )
      .map((fieldName) => fieldDisplayNameMap[fieldName]) as string[];
  }

  public saveMember(): void {
    // console.log('Inside save member:', this.updateMemberFormGroup);
    if (this.updateMemberFormGroup.valid) {
      const reqObj: MemberDetails = {
        patientId: this.memberDetails.patientId,
        clientId: this.memberDetails.clientId,
        firstName: this.updateMemberFormGroup.value.firstName.trim(),
        lastName: this.updateMemberFormGroup.value.lastName.trim(),
        middleName: this.updateMemberFormGroup.value.middleName.trim(),
        DOB: formatDate(new Date(this.updateMemberFormGroup.value.dob)),
        gender: this.updateMemberFormGroup.value.gender,
        clientInternalMemberId:
          this.updateMemberFormGroup.value.clientInternalMemberId,
        SSN: clearSsnFormat(this.updateMemberFormGroup.value.ssn),
        email: this.memberDetails.email,
        addressLine1: this.memberDetails.addressLine1,
        addressLine2: this.memberDetails.addressLine2,
        zipCode: this.memberDetails.zipCode,
        city: this.memberDetails.city,
        state: this.memberDetails.state,
        effectiveDate: this.memberDetails.coverage.startDate,
        terminationDate: this.memberDetails.coverage.endDate,
        isUpdated: true,
        personCode: this.memberDetails.personCode,
        relationshipCode: this.updateMemberFormGroup.value.relationshipCode,
        homePhone: this.getPhoneNumber('homePhone'),
        cellPhone: this.getPhoneNumber('cellPhone'),
        workPhone: this.getPhoneNumber('workPhone'),
        cardId: this.memberDetails.cardId,
        groupNumber: this.memberDetails.coverage?.groupNumber,
        coverageType: this.memberDetails.coverage?.coverageType,
        flexTagName: this.memberDetails.flexTag.flexTagName,
        flexTagStartDate: this.memberDetails.flexTag.flexTagStartDate,
        flexTagEndDate: this.memberDetails.flexTag.flexTagEndDate,
        flexTagName2: this.memberDetails.flexTag2.flexTagName,
        flexTagStartDate2: this.memberDetails.flexTag2.flexTagStartDate,
        flexTagEndDate2: this.memberDetails.flexTag2.flexTagEndDate,
        flexTagName3: this.memberDetails.flexTag3.flexTagName,
        flexTagStartDate3: this.memberDetails.flexTag3.flexTagStartDate,
        flexTagName4: this.memberDetails.flexTag4.flexTagName,
        flexTagStartDate4: this.memberDetails.flexTag4.flexTagStartDate,
        flexTagEndDate4: this.memberDetails.flexTag4.flexTagEndDate,
        flexTagName5: this.memberDetails.flexTag5.flexTagName,
        flexTagStartDate5: this.memberDetails.flexTag5.flexTagStartDate,
        flexTagEndDate5: this.memberDetails.flexTag5.flexTagEndDate,
        flexTagEndDate3: this.memberDetails.flexTag3.flexTagEndDate,
      };
      // console.log('Update member request:', reqObj);
      this.loader.showLoader();
      this.loader.showWaitingMessage$.next(AppConstants.ERROR.WAIT_MESSAGE_FOR_UPDATE_MEMBER);
      this.eligibilityService.addMember(reqObj).subscribe({
        next: (res) => {
          if (res) {
            this.updatedMember = res;
            this.loader.hideLoader();
            this.loader.showWaitingMessage$.next(null);
            const dialogRef: MatDialogRef<ErrorComponent> = this.matDialog.open(
              ErrorComponent,
              {
                width: '300px',
                data: {
                  title: 'Success',
                  message: 'Member updated successfully',
                },
              }
            );
            dialogRef.afterClosed().subscribe(() => {
              this.navigateBackToMemberSearch();
            });
          }
        },
        error: (err) => {
          this.createAuditLog(false, err);
          // console.log(err);
          const message = getErrorMessage(err);
          this.errorManager.throwError({
            type: ErrorType.ERROR_GENERIC,
            message: message,
            component: 'Update member',
            title: '',
          });
          this.loader.hideLoader();
          this.loader.showWaitingMessage$.next(null);
        },
        complete: () => {
          this.createAuditLog(true);
        },
      });
    }
  }

  public getPhoneNumber(phoneType: string): string {
    return this.memberDetails.phones.filter((p) => p.type === phoneType)[0]
      .phoneNumber;
  }

  createAuditLog(success: boolean, err?: HttpErrorResponse) {
    const reqBody: SaveAuditLogsRequestInterface = {
      clientId: this.memberDetails.clientId,
      cardId: this.memberDetails.cardId,
      personCd: this.updatedMember.personCode ? this.updatedMember.personCode : this.memberDetails.personCode,
      firstName: this.updatedMember.firstName ? this.updatedMember.firstName : this.memberDetails.firstName,
      lastName: this.updatedMember.lastName ? this.updatedMember.lastName : this.memberDetails.lastName,
      emailId: this.updatedMember.email && this.updatedMember.email.toLowerCase() !== 'null'
        ? this.updatedMember.email
        : this.authService.userEmail,
      birthDt: this.updatedMember.dateOfBirth ? this.updatedMember.dateOfBirth : this.memberDetails.DOB,
      groupNo: this.memberDetails.coverage.groupNumber,
      groupName: this.groupInfo.groupName,
      changes:
        AppConstants.AUDIT_LOGS.updateMember.action.updateMember.change,
      comments:
        AppConstants.AUDIT_LOGS.updateMember.action.updateMember.comments.replace(
          '%FIELD_NAME%',
          this.updatedFieldsArray().join(', ')
        ),
      addedBy: this.authService.userEmail,
      isSuccess: success,
    };
    if (!success) {
      const message = err?.error?.Message ? err.error.Message : JSON.stringify(err);
      reqBody.httpStatusCode = err.status,
        reqBody.errorMsg = message
    }
    // console.log('Request Body create audit log:', reqBody);
    this.auditLogsService.saveAuditLog(reqBody).subscribe({
      next: (res) => {
        // console.log('Response on create audit log:', res);
        this.appInsightsService.trackPageView(AppCenterAnalytics.SAVE_AUDIT_LOG_API_SUCCESS, { Response: res });
      },
      error: (err) => {
        this.appInsightsService.trackPageView(AppCenterAnalytics.SAVE_AUDIT_LOG_API_ERROR, { Response: err });
        // console.log('Error on create audit log:', err);
      },
      complete: () => { },
    });
  }
}
